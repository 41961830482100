import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Box, Typography } from '@mui/material';

const ContactUs = () => {
  const InstaIcon = (props) => (
    <InstagramIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </InstagramIcon>
  );

  const WhatIcon = (props) => (
    <WhatsAppIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </WhatsAppIcon>
  );

  const LinkIcon = (props) => (
    <LinkedInIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </LinkedInIcon>
  );

  return (
    <>
      <Box mt={15} sx={{ height: '200px', mb: 13 }}>
        <Grid container>
          <Grid xs={12}>
            <Box>
              <Typography
                variant="h2"
                textAlign="center"
                color="primary"
                fontWeight="bold"
                fontSize="2.5rem"
                textTransform="uppercase"
                mb={1}
              >
                Contáctenos
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={2}
                mb={1}
              >
                Correo electrónico
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                color="initial"
              >
                mabel.troncoso@btsolutiongrp.com
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                fontFamily="sans-serif"
                color="initial"
              >
                rafael.belliard@btsolutiongrp.com
              </Typography>
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={3}
              >
                Teléfono
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                fontFamily="sans-serif"
                color="initial"
              >
                809-884-6375
              </Typography>
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={3}
              >
                Dirección
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                fontFamily="sans-serif"
                color="initial"
                textAlign="center"
              >
                Santo Domingo, Distrito Nacional, Republica Dominicana.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={2}
                mb={1}
              >
                Redes Sociales
              </Typography>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  '& > :not(style)': {
                    m: 1,
                    mt: 2
                  }
                }}
              >
                <Typography
                  mt={1}
                  ml={1}
                  variant="h2"
                  fontSize="20px"
                  fontFamily="sans-serif"
                  color="initial"
                >
                  <a
                    href="https://www.instagram.com/btsolutiongroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstaIcon sx={{ color: '#E4405F', fontSize: 52 }} />
                  </a>
                  Instagram
                </Typography>
                <Typography
                  mt={1}
                  ml={1}
                  variant="h2"
                  fontSize="20px"
                  fontFamily="sans-serif"
                  color="initial"
                >
                  <a
                    href="https://bit.ly/BTSolutionGroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatIcon sx={{ color: '#14a412', fontSize: 45 }} />
                  </a>
                  WhatsApp
                </Typography>
                <Typography
                  mt={1}
                  ml={1}
                  variant="h2"
                  fontSize="20px"
                  fontFamily="sans-serif"
                  color="initial"
                >
                  <a
                    href="https://www.linkedin.com/in/bt-solutiongroup-4b178210a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkIcon sx={{ color: '#0f46a5', fontSize: 45 }} />
                  </a>
                  LinkedIn
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactUs;
