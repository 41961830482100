import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ContactsIcon from '@mui/icons-material/Contacts';
import NavListDrawer from './NavListDrawer';
import { NavLink } from 'react-router-dom';

const navLinks = [
  {
    title: 'Inicio',
    path: '/',
    icon: <HomeIcon color="primary" />
  },
  {
    title: 'Sobre nosotros',
    path: '/about',
    icon: <InfoIcon color="primary" />
  },
  {
    title: 'Servicios',
    path: '/services',
    icon: <DisplaySettingsIcon color="primary" />
  },
  {
    title: 'Contáctanos',
    path: '/contact',
    icon: <ContactsIcon color="primary" />
  }
];

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar>
          <IconButton
            color="inherit"
            size="large"
            onClick={() => setOpen(true)}
            sx={{ display: { xs: 'flex', sm: 'none' } }}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h1" fontSize="22px" sx={{ flexGrow: 1 }}>
            B&T Solution Group
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navLinks.map((item) => (
              <Button
                color="inherit"
                key={item.title}
                component={NavLink}
                to={item.path}
              >
                {item.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        open={open}
        anchor="left"
        onClose={() => setOpen(false)}
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <NavListDrawer
          navLinks={navLinks}
          NavLink={NavLink}
          setOpen={setOpen}
        />
      </Drawer>
    </>
  );
};

export default Navbar;
