import React from 'react';
import Mision from '../../assets/images/mision.png';
import Vision from '../../assets/images/vision.png';
import Abaout from '../../assets/images/sobre.png';
import Consulta from '../../assets/images/consult.png';
import Prevencion from '../../assets/images/prevencion.png';
import Project from '../../assets/images/project.png';
import ImagenLogo from './../../assets/images/logo.png';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import ContactUs from '../Contact/ContactUs';

const Home = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1, justifyContent: 'center', p: 1, mb: 53 }}>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
              mt={15}
              mb={10}
              p={2}
            >
              <img
                src={ImagenLogo}
                className="img-fluid"
                alt="B&T Solution Group"
              />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box>
              <Typography
                variant="h2"
                textAlign="center"
                color="primary"
                fontWeight="bold"
                fontSize="2.5rem"
                textTransform="uppercase"
                mb={2}
                mt={17}
              >
                Nosotros
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4} spacing={3}>
            <Box mt={5} mb={5} alignContent="left" width="100%">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="160"
                  src={Mision}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    color="primary"
                    component="div"
                  >
                    Misión
                  </Typography>

                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                  >
                    Constribuir al desarrollo sostenible de las organizaciones
                    mediante la aplicación de soluciones innovadoras en el
                    ámbito de la gestión de los recursos humanos, proyectos, la
                    seguridad, la salud y el medio ambiente.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to="/about"
                    className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    Ver más
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4} spacing={3}>
            <Box mt={5} mb={5} alignContent="center" width="100%">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="160"
                  src={Vision}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    color="primary"
                    component="div"
                  >
                    Visión
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                  >
                    Ser la empresa consultora de soluciones innovadoras de
                    Gestión de Riesgos Laborales y Administración de Recursos
                    Humanos de preferencia en la República Dominicana.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to="/about"
                    className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    Ver más
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
          <Grid xs={12} md={4} mb={8} spacing={6}>
            <Box mt={5} alignContent="end" width="100%">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="160"
                  src={Abaout}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    color="primary"
                    component="div"
                  >
                    ¿Quienes somos?
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                    mb={3}
                  >
                    B&T Solution Group nace ante la necesidad de proveer
                    soluciones diferenciadoras de consultoría integral de
                    administración de riesgos laborales, gestión de proyectos y
                    administración del capital humano. Nos dedicamos a brindar
                    consultoría en Gestión Humana y Prevención de Riesgos
                    Laborables.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to="/about"
                    className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    Ver más
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box>
              <Typography
                variant="h2"
                textAlign="center"
                color="primary"
                fontWeight="bold"
                fontSize="2.5rem"
                textTransform="uppercase"
                mt={5}
                mb={1}
              >
                Servicios
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4} spacing={3}>
            <Box mt={5} mb={5} width="100%">
              <Card sx={{ maxWidth: 355 }}>
                <CardMedia
                  component="img"
                  src={Consulta}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="bold"
                    textTransform="uppercase"
                    color="primary"
                    component="h3"
                  >
                    Consultoria en la gestión integral humana de gestión humana
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                    fontWeight=""
                  >
                    Atracción de Talento, Capacitación, Entrenamientos,
                    Desarrollo de Personal, Manual de Politicas y
                    Procedimientos, Manual de Descripciones de Puestos, Planes
                    de sucesión, Relaciones Laborales y Coaching Personal.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to="/services"
                    className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    Ver más
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4} spacing={3}>
            <Box mt={5} mb={5} width="100%">
              <Card sx={{ maxWidth: 355 }}>
                <CardMedia
                  component="img"
                  src={Prevencion}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="bold"
                    textTransform="uppercase"
                    color="primary"
                    component="h3"
                  >
                    Consultoría en gestión de prevención de riesgos laborables
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                    fontWeight=""
                  >
                    Seguridad, Salud Organizacional, Medio Ambiente, Asesoria
                    con Relación Normas y Regulaciones Nacionales e
                    Internacionales.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to="/services"
                    className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    Ver más
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4} spacing={3}>
            <Box mt={5} mb={5} width="100%">
              <Card sx={{ maxWidth: 355 }}>
                <CardMedia
                  component="img"
                  src={Project}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="bold"
                    textTransform="uppercase"
                    color="primary"
                    component="h3"
                  >
                    Gestión de proyectos
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                    fontWeight=""
                  >
                    Planificación, coordinación y supervisión de gestión de
                    proyectos desde la concepción hasta la finalización. Nuestro
                    enfoque está en maximizar la eficiencia, minimizar los
                    riesgos y cumplir con sus objetivos especificos.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link
                    to="/services"
                    className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    Ver más
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <ContactUs />
      </Box>
    </>
  );
};

export default Home;
