import React from 'react';
import Logo from '../../assets/images/icon.png';
import Mabel from '../../assets/images/picture-t.png';
import Consulta from '../../assets/images/consult.png';
import Prevencion from '../../assets/images/prevencion.png';
import Project from '../../assets/images/project.png';
import Belliard from '../../assets/images/picture-b.png';
import Jennifer from '../../assets/images/Jennifer.png';
import Evelin from '../../assets/images/Evelin.png';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';

const Services = () => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          mt: 2,
          mb: 30
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography
                variant="h2"
                textAlign="center"
                color="primary"
                fontWeight="bold"
                fontSize="2.5rem"
                textTransform="uppercase"
                mt={5}
                mb={1}
              >
                Servicios
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={5} mb={10} width="100%">
              <Card sx={{ maxWidth: 355 }}>
                <CardMedia
                  component="img"
                  src={Consulta}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="bold"
                    textTransform="uppercase"
                    color="primary"
                    component="h3"
                  >
                    Consultoria en la gestión integral humana de gestión humana
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                    fontWeight=""
                  >
                    Atracción de Talento, Capacitación, Entrenamientos,
                    Desarrollo de Personal, Manual de Politicas y
                    Procedimientos, Manual de Descripciones de Puestos, Planes
                    de sucesión, Relaciones Laborales y Coaching Personal.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={5} mb={10} width="100%">
              <Card sx={{ maxWidth: 355 }}>
                <CardMedia
                  component="img"
                  src={Prevencion}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="bold"
                    textTransform="uppercase"
                    color="primary"
                    component="h3"
                  >
                    Consultoría en gestión de prevención de riesgos laborables
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                    fontWeight=""
                  >
                    Seguridad, Salud Organizacional, Medio Ambiente, Asesoria
                    con Relación Normas y Regulaciones Nacionales e
                    Internacionales.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box mt={5} mb={10} width="100%">
              <Card sx={{ maxWidth: 355 }}>
                <CardMedia
                  component="img"
                  src={Project}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '250px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    fontSize="18px"
                    fontWeight="bold"
                    textTransform="uppercase"
                    color="primary"
                    component="h3"
                  >
                    Gestión de proyectos
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                    fontWeight=""
                  >
                    Planificación, coordinación y supervisión de gestión de
                    proyectos desde la concepción hastala la finalización.
                    Nuestro enfoque está en maximizar la eficiencia, minimizar
                    los riesgos y cumplir con sus objetivos especificos.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 12
              }}
            >
              <img
                src={Mabel}
                width="400px"
                className="img-fluid"
                alt="Mabel Troncoso"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 20,
                mt: 2
              }}
            >
              <img
                src={Logo}
                width={190}
                className="img-fluid"
                alt="B&T Solution Group"
              />

              <Typography
                sx={{
                  fontFamily: 'sans-serif',
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  mb: 3
                }}
                component="h1"
                color="primary"
              >
                Mabel Troncoso
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  width: '100%',
                  fontSize: '1.7rem',
                  fontWeight: 'bold',
                  lineHeight: 'revert',
                  backgroundColor: '#82729e',
                  textAlign: 'center',
                  color: '#ffffff',
                  fontFamily: 'Quicksand'
                }}
              >
                Directora Comercial
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '100%',
                  fontSize: '18px',
                  textAlign: 'center',
                  padding: 4,
                  backgroundColor: '#4b0082',
                  color: '#ffffff'
                }}
              >
                Psicóloga Industrial, egresada de Universidad Iberoamericana,
                UNIBE obteniendo el grado de Psicología Industrial, Magna Cum
                Laude, con una Maestria en Dirección Estratégica de Recursos
                Humanos con doble titulación en las Universidades Pontificia
                Universidad Católica Madre y Maestra, PUCMM y la Universidad
                Escuela de Organización Industrial, EOI, España. Certificación
                de la International Coaching Community en Coach Ejecutivo.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1,
                mt: 20
              }}
            >
              <img
                src={Belliard}
                width="400px"
                className="img-fluid"
                alt="Rafael Belliard"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 9
              }}
            >
              <img
                src={Logo}
                width={190}
                className="img-fluid"
                alt="B&T Solution Group"
              />

              <Typography
                sx={{
                  fontFamily: 'sans-serif',
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  mb: 3
                }}
                component="h1"
                color="primary"
              >
                Rafael Belliard
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  width: '100%',
                  fontFamily: 'Quicksand',
                  fontSize: '1.7rem',
                  fontWeight: 'bold',
                  lineHeight: 'revert',
                  backgroundColor: '#82729e',
                  textAlign: 'center',
                  color: '#ffffff'
                }}
              >
                Director de Operaciones
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '100%',
                  fontSize: '18px',
                  textAlign: 'center',
                  padding: 4,
                  backgroundColor: '#4b0082',
                  color: '#ffffff'
                }}
              >
                Ingeniero Industrial, egresado del Instituto Tecnológico de
                Santo Domingo (INTEC), cuenta con maestrias en Gestión Integrada
                (MGI) en Calidad, Prevención de Riesgos Laborales y Medio
                Ambiente (Universidad de A Coruña) & EDAE Alta Escuela de
                Dirección y Administración de Empresas y una Maestria en
                Administración de Recursos Humanos (MRHH): PUCMM & EOI.
                <br />
                <br />
                Actualmente cursando estudios de Doctorado en Gestion de
                Proyectos: Mención Prevención de Riesgos Laborales: Fundación
                Universitaria Iberoamericana y Universidad Internacional
                Iberoamericana (Puerto Rico).
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1,
                mt: 10,
                mb: -5
              }}
            >
              <img
                src={Jennifer}
                width="400px"
                className="img-fluid"
                alt="Rafael Belliard"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 12
              }}
            >
              <img
                src={Logo}
                width={190}
                className="img-fluid"
                alt="B&T Solution Group"
              />

              <Typography
                sx={{
                  fontFamily: 'sans-serif',
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  mb: 3
                }}
                component="h1"
                color="primary"
              >
                Jennifer Pujols
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  width: '100%',
                  fontFamily: 'Quicksand',
                  fontSize: '1.7rem',
                  fontWeight: 'bold',
                  lineHeight: 'revert',
                  backgroundColor: '#82729e',
                  textAlign: 'center',
                  color: '#ffffff'
                }}
              >
                Consultora Gestión Humana
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '100%',
                  fontSize: '18px',
                  textAlign: 'center',
                  padding: 4,
                  backgroundColor: '#4b0082',
                  color: '#ffffff'
                }}
              >
                Jennifer Pujols. Psicóloga Laboral, egresada se la Universidad
                Católica de Santo Domingo, UCSD, obteniendo el grado de Lic.
                Psicología Laboral, cuenta con una Maestria en Gerencia de
                Recursos Humanos, de la Universidad APEC. Mas de 15 anos en
                procesos de Gestión estratégica del talento humano.
                Especializada en procesos de Reclutamiento, Selección y
                Contratación de personal
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1,
                mt: 12,
                mb: -10
              }}
            >
              <img
                src={Evelin}
                width="400px"
                className="img-fluid"
                alt="Rafael Belliard"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 17
              }}
            >
              <img
                src={Logo}
                width={190}
                className="img-fluid"
                alt="B&T Solution Group"
              />

              <Typography
                sx={{
                  fontFamily: 'sans-serif',
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  mb: 3
                }}
                component="h1"
                color="primary"
              >
                Evelin Torres
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  width: '100%',
                  fontFamily: 'Quicksand',
                  fontSize: '1.7rem',
                  fontWeight: 'bold',
                  lineHeight: 'revert',
                  backgroundColor: '#82729e',
                  textAlign: 'center',
                  color: '#ffffff'
                }}
              >
                Consultora de Seguridad Salud y Medio Ambiente
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: '100%',
                  fontSize: '18px',
                  textAlign: 'center',
                  padding: 4,
                  backgroundColor: '#4b0082',
                  color: '#ffffff'
                }}
              >
                Psicologa, con Post Grado en Recursos Humanos y Master en
                Seguridad y Salud, Coach Profesional, avalada por la Federación
                Internacional de Coaching (ICF), experiencia en la elaboración
                de programas de Seguridad y Salud, Asesorias Empresariales,
                Capacitaciones y docencia universitaria.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Services;
