import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Box } from '@mui/system';

const NavListDrawer = ({ navLinks, NavLink, setOpen }) => {
  return (
    <>
      <Box sx={{ width: 250 }}>
        <nav>
          <List>
            {navLinks.map((item) => (
              <ListItem disablePadding key={item.title}>
                <ListItemButton
                  component={NavLink}
                  to={item.path}
                  onClick={() => setOpen(false)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    </>
  );
};

export default NavListDrawer;

NavListDrawer.propTypes = {
  navLinks: PropTypes.array.isRequired,
  NavLink: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired
};
