import React from 'react';
import AboutUs from '../../assets/images/logo.png';
import Mision from '../../assets/images/mision.png';
import Vision from '../../assets/images/vision.png';
import Abaout from '../../assets/images/sobre.png';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';

const About = () => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          mt: 5
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography
                variant="h2"
                textAlign="center"
                color="primary"
                fontWeight="bold"
                fontSize="2.5rem"
                textTransform="uppercase"
                mb={10}
                mt={2}
              >
                Nosotros
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 16,
                mb: 10
              }}
            >
              <img
                width={400}
                src={AboutUs}
                className="img-fluid"
                alt="B&T Solution Group"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 10,
                mb: 30
              }}
            >
              <Typography
                sx={{
                  fontSize: '40px',
                  fontWeight: 'bold',
                  mt: 5,
                  mb: 3,
                  textAlign: 'center'
                }}
                variant="h3"
                color="primary"
              >
                B&T SOLUTION GROUP
              </Typography>

              <Typography
                component="p"
                sx={{
                  fontFamily: 'sans-serif',
                  fontSize: '24px',
                  textAlign: 'center'
                }}
              >
                B&T Solution Group nace ante la necesidad de proveer soluciones
                diferenciadoras de consultoría integral de administración de
                riesgos laborales, gestión de proyectos y administración del
                capital humano.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={5} mb={5} alignContent="left" width="100%">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="160"
                  src={Mision}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '240px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    color="primary"
                    component="div"
                  >
                    Misión
                  </Typography>

                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                  >
                    Constribuir al desarrollo sostenible de las organizaciones
                    mediante la aplicación de soluciones innovadoras en el
                    ámbito de la gestión de los recursos humanos, proyectos, la
                    seguridad, la salud y el medio ambiente.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mt={5} mb={5} alignContent="center" width="100%">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="160"
                  src={Vision}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '240px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    color="primary"
                    component="div"
                  >
                    Visión
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                  >
                    Ser la empresa consultora de soluciones innovadoras de
                    Gestión de Riesgos Laborables y Administración de Recursos
                    Humanos de preferencia en la Republica Dominicana.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} mb={8}>
            <Box mt={5} alignContent="end" width="100%">
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="160"
                  src={Abaout}
                  alt="B&T Solution Group"
                />
                <CardContent sx={{ height: '240px' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    color="primary"
                    component="div"
                  >
                    ¿Quienes somos?
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    color="#000000"
                  >
                    Nos dedicamos a brindar consultoría en Gestión Humana y
                    Prevención de Riesgos Laborables.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default About;
