import React, { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import SendIcon from '@mui/icons-material/Send';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

const Contact = () => {
  const InstaIcon = (props) => (
    <InstagramIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </InstagramIcon>
  );

  const WhatIcon = (props) => (
    <WhatsAppIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </WhatsAppIcon>
  );

  const LinkIcon = (props) => (
    <LinkedInIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </LinkedInIcon>
  );

  const [formulario, setFormulario] = useState({
    nombre: '',
    correo: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value
    });
  };

  // Funcion para enviar Email
  const handleOnSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_nwo0f3p',
        'template_256jqsk',
        e.target,
        'koEQW84JyDOVGR0tr'
      )
      .then(
        (result) => {
          if (result.text) {
            Swal.fire(
              '¡Mensaje enviado!',
              '¡Su mensaje ha sido enviado correctamente!',
              'success'
            );
            e.target.reset();
          }
        },
        (error) => {
          if (error.text) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: '¡Ha ocurrido un error!'
            });
          }
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          mt: 2
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 14,
                mb: 20
              }}
            >
              <img
                src={Logo}
                width={500}
                className="img-fluid"
                alt="B&T Solution Group"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 9,
                mb: 20
              }}
            >
              <form onSubmit={handleOnSubmit}>
                <Typography
                  sx={{
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    fontSize: '2.3rem',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    mb: 5
                  }}
                  variant="h2"
                  color="primary"
                >
                  Contáctanos
                </Typography>

                <TextField
                  sx={{
                    mb: 3
                  }}
                  fullWidth
                  type="text"
                  name="nombre"
                  id="nombre"
                  label="Nombre"
                  onChange={handleChange}
                  required
                />
                <TextField
                  sx={{
                    mb: 3
                  }}
                  fullWidth
                  type="email"
                  name="correo"
                  id="correo"
                  label="Correo Electrónico"
                  onChange={handleChange}
                  required
                />
                <TextField
                  sx={{
                    height: '200px',
                    mb: 1
                  }}
                  type="text"
                  name="mensaje"
                  id="mensaje"
                  label="Mensaje"
                  rows={6}
                  multiline
                  fullWidth
                  onChange={handleChange}
                  required
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ height: 60 }}
                  startIcon={<SendIcon />}
                >
                  Enviar
                </Button>
              </form>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={2}
                mb={1}
              >
                Correo electrónico
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                color="initial"
              >
                mabel.troncoso@btsolutiongrp.com
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                fontFamily="sans-serif"
                color="initial"
              >
                rafael.belliard@btsolutiongrp.com
              </Typography>
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={3}
              >
                Teléfono
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                fontFamily="sans-serif"
                color="initial"
              >
                809-884-6375
              </Typography>
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={3}
              >
                Dirección
              </Typography>
              <Typography
                mt={1}
                ml={1}
                variant="h2"
                fontSize="20px"
                fontFamily="sans-serif"
                color="initial"
                textAlign="center"
              >
                Santo Domingo, Distrito Nacional, Republica Dominicana.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="h2"
                fontSize="22px"
                fontWeight="bold"
                color="primary"
                mt={2}
                mb={1}
              >
                Redes Sociales
              </Typography>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  '& > :not(style)': {
                    m: 1,
                    mt: 2
                  }
                }}
              >
                <Typography
                  mt={1}
                  ml={1}
                  variant="h2"
                  fontSize="20px"
                  fontFamily="sans-serif"
                  color="initial"
                >
                  <a
                    href="https://www.instagram.com/btsolutiongroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstaIcon sx={{ color: '#E4405F', fontSize: 52 }} />
                  </a>
                  Instagram
                </Typography>
                <Typography
                  mt={1}
                  ml={1}
                  variant="h2"
                  fontSize="20px"
                  fontFamily="sans-serif"
                  color="initial"
                >
                  <a
                    href="https://bit.ly/BTSolutionGroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatIcon sx={{ color: '#14a412', fontSize: 45 }} />
                  </a>
                  WhatsApp
                </Typography>
                <Typography
                  mt={1}
                  ml={1}
                  variant="h2"
                  fontSize="20px"
                  fontFamily="sans-serif"
                  color="initial"
                >
                  <a
                    href="https://www.linkedin.com/in/bt-solutiongroup-4b178210a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkIcon sx={{ color: '#0f46a5', fontSize: 45 }} />
                  </a>
                  LinkedIn
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
