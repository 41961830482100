import React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Footer = () => {
  const fecha = new Date().getFullYear();

  const InstaIcon = (props) => (
    <InstagramIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </InstagramIcon>
  );

  const WhatIcon = (props) => (
    <WhatsAppIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </WhatsAppIcon>
  );

  const LinkIcon = (props) => (
    <LinkedInIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </LinkedInIcon>
  );

  const EmailIcon = (props) => (
    <MailOutlineIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </MailOutlineIcon>
  );

  return (
    <>
      <Box
        component="div"
        sx={{
          width: '100%',
          height: 160,
          mt: 20,
          mb: -5.4,
          backgroundColor: 'primary.dark'
        }}
      >
        <Grid container>
          <Grid xs={12}>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                '& > :not(style)': {
                  m: 1,
                  mt: 2
                }
              }}
            >
              <a
                href="https://www.instagram.com/btsolutiongroup"
                target="_blank"
                rel="noreferrer"
              >
                <InstaIcon sx={{ color: '#E4405F', fontSize: 52 }} />
              </a>
              <a
                href="https://bit.ly/BTSolutionGroup"
                target="_blank"
                rel="noreferrer"
              >
                <WhatIcon sx={{ color: '#14a412', fontSize: 45 }} />
              </a>
              <a
                href="https://www.linkedin.com/in/bt-solutiongroup-4b178210a"
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon sx={{ color: '#0f46a5', fontSize: 45 }} />
              </a>
              <a
                href="mailto:mabel.troncoso@btsolutiongrp.com?subject=%20Informaci%C3%B3n%20de%20los%20Servicios%20que%20Ofrecen.&body=%C2%A1Saludos%20cordiales!%0A%0AVengo%20por%20m%C3%A1s%20Informaci%C3%B3n%20de%20los%20servicios%20que%20ofrecen%2C%20los%20contacto%20a%20trav%C3%A9s%20de%20su%20sitio%20web"
                target="_blank"
                rel="noreferrer"
              >
                <EmailIcon sx={{ color: '#4ebbe9', fontSize: 45 }} />
              </a>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
              p={2}
            >
              <Typography
                sx={{ margin: '0px auto' }}
                variant="h1"
                textAlign="center"
                fontSize="18px"
                justifyContent="center"
                color="secondary"
              >
                &copy; {fecha} B&T Solution Group. Todos los derechos
                reservados.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
